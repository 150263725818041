<template>
  <div class="view pa24">
    <div style="display: flex;flex: 1">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          :class="ruleForm.floorStyle <= 17&&ruleForm.floorStyle>0?'ruleForm':'ruleForm1'"
      >
        <el-form-item label="楼层名称" prop="floorName">
          <el-input
              clearable
              v-model="ruleForm.floorName"
              class="w400"
              placeholder="请输入楼层名称"
              maxlength="200"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="楼层类型" prop="floorType">
          <el-radio-group v-model="ruleForm.floorType" :disabled="ifXG">
            <el-radio :label="0">系统内部</el-radio>
            <el-radio :label="1">自定义</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="ruleForm.sort" class="w400"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="radio">
          <el-radio-group v-model="ruleForm.radio">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="楼层样式" prop="floorStyle" style="margin-top: 10px">
          <el-select v-model="ruleForm.floorStyle" :disabled="ifJY" @change="choose_type" placeholder="请选择"
                     class="w400">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button class="save ml10" type="info" @click="reselect">重新选择</el-button>
        </el-form-item>
        <el-form-item v-if="ruleForm.floorType == 0">
          <div class="con_wrap">
            <div class="decorate-center" v-if="ruleForm.floorStyle == 0">
              <div class="left_wrap">
                <div class="banner">
                  <el-carousel height="400px">
                    <el-carousel-item v-for="(item,index) in swiperList" :key="index">
                      <img :src="item.url" alt="">
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
              <div class="attribute-tabs">
                <div class="flex attribute-header">
                  <div class="attribute-title">轮播图</div>
                </div>
                <div class="attribute-content">
                  <div>
                    <form class="el-form el-form--label-left">
                      <div class="attribute-item">
                        <div class="title flex"> 图片设置<span
                            class="muted xs flex-1 m-l-8">最多可添加10张，建议图片尺寸：1020px*440px</span></div>
                        <div class="content1">
                          <div class="nav-content">
                            <div class="nav-list">
                              <div v-for="(item,index) in swiperList" :key="index" style="position: relative"
                                   @mouseenter="toMouseenter1(index)"
                                   @mouseleave="toMouseleave1(index)">
                                <div v-if="item.isDel" @click="del1(index)">
                                  <i class="el-icon-close ls-icon-del"></i>
                                </div>
                                <div class="nav-item ls-del-wrap">
                                  <div class="flexColumn">
                                    <div class="smallImg" @click="toImg(index)">
                                      <el-upload :action="uploadUrl"
                                                 list-type="picture-card"
                                                 :on-remove="handleRemove"
                                                 :on-success="handleSuccess">
                                        <img v-if="item.url" :src="item.url" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                      </el-upload>
                                    </div>
                                    <div class="input_wrap1">
                                      <label class="el-form-item__label">链接地址</label>
                                      <div class="el-input--small" style="width: 100%;" @click="toChooseUrl1(index)">
                                        <el-input style="width: 200px;cursor: pointer" type="text" placeholder="请选择跳转链接"
                                                  clearable
                                                  v-model="item.title"></el-input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="" style="text-align: center;width: 100%" v-if="navlist.length<=10">
                                <el-button type="" style="width: 100%" @click="add1">添加图片</el-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle <= 7&&ruleForm.floorStyle>0">
              <div>
                <div class="style_img">
                  <div class="title_wtap">
                    <div class="choose_title" @click="chooseType(ruleForm.floorStyle,1)">
                      {{ title }}
                    </div>
                    <div class="button_wrap">
                      <div class="button" @click="chooseType(ruleForm.floorStyle,2)">
                        <img src="../../assets/images/update.png" alt="">
                        <span>修改</span>
                      </div>
                      <div class="button" @click="deleteType()">
                        <img src="../../assets/images/zidingyiDel.png" alt="">
                        <span>删除</span>
                      </div>
                    </div>
                  </div>
                  <template v-if="ruleForm.floorStyle == 1">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video1.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 2">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video2.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 3">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video3.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 4">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video4.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 5">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video5.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 6">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video6.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 7">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video7.png" alt="">
                  </template>
                </div>
                <div class="choose_nr" @click="chooseNR(ruleForm.floorStyle)"> + 选择内容</div>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle > 7&&ruleForm.floorStyle<=17">
              <div>
                <div class="style_img">
                  <div class="title_wtap">
                    <div class="choose_title" @click="chooseType(ruleForm.floorStyle,1)">
                      {{ title }}
                    </div>
                    <div class="button_wrap">
                      <div class="button" @click="chooseType(ruleForm.floorStyle,2)">
                        <img src="../../assets/images/update.png" alt="">
                        <span>修改</span>
                      </div>
                      <div class="button" @click="deleteType()">
                        <img src="../../assets/images/zidingyiDel.png" alt="">
                        <span>删除</span>
                      </div>
                    </div>
                  </div>
                  <template v-if="ruleForm.floorStyle == 8">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article1.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 9">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article2.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 10">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article3.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 11">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article4.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 12">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article5.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 13">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article6.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 14">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article7.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 15">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article8.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 16">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article9.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 17">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article10.png"
                         alt="">
                  </template>
                </div>
                <div class="choose_nr" @click="chooseNR(ruleForm.floorStyle)"> + 选择内容</div>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle > 17&&ruleForm.floorStyle<=20">
              <div style="display: flex">
                <template v-if="ruleForm.floorStyle == 18">
                  <div v-for="(item,index) in zhuanTi1" :key="index" style="position: relative;"
                       @click="toZTImg(index)">
                    <div class="zt1_wrap">
                      <el-upload :action="uploadUrl"
                                 list-type="picture-card"
                                 :on-remove="handleRemove2"
                                 :on-success="handleSuccess2">
                        <img v-if="item.jumpUrl" :src="item.jumpUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="input_wrap1 marginTop">
                        <label class="el-form-item__label">链接地址</label>
                        <div class="el-input--small" style="width: 100%;" @click="toChooseztUrl(index)">
                          <el-input style="width: 200px;cursor: pointer" type="text" placeholder="请选择跳转链接"
                                    clearable
                                    v-model="item.title"></el-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="ruleForm.floorStyle == 19">
                  <div v-for="(item,index) in zhuanTi2" :key="index" style="position: relative;margin-right: 20px"
                       @click="toZTImg(index)">
                    <div class="zt2_wrap">
                      <el-upload :action="uploadUrl"
                                 list-type="picture-card"
                                 :on-remove="handleRemove2"
                                 :on-success="handleSuccess2">
                        <img v-if="item.jumpUrl" :src="item.jumpUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="input_wrap1 marginTop">
                        <label class="el-form-item__label">链接地址</label>
                        <div class="el-input--small" style="width: 100%;" @click="toChooseztUrl(index)">
                          <el-input style="width: 200px;cursor: pointer" type="text" placeholder="请选择跳转链接"
                                    clearable
                                    v-model="item.title"></el-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="ruleForm.floorStyle == 20">
                  <div v-for="(item,index) in zhuanTi3" :key="index" style="position: relative;margin-right: 20px"
                       @click="toZTImg(index)">
                    <div class="zt3_wrap">
                      <el-upload :action="uploadUrl"
                                 list-type="picture-card"
                                 :on-remove="handleRemove2"
                                 :on-success="handleSuccess2">
                        <img v-if="item.jumpUrl" :src="item.jumpUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="input_wrap1 marginTop">
                        <label class="el-form-item__label">链接地址</label>
                        <div class="el-input--small" style="width: 100%;" @click="toChooseztUrl(index)">
                          <el-input style="width: 200px;cursor: pointer" type="text" placeholder="请选择跳转链接"
                                    clearable
                                    v-model="item.title"></el-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle == 21">
              <div>
                <div class="style_img">
                  <template>
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article8.png"
                         alt="">
                  </template>
                </div>
                <div class="choose_nr" @click="chooseShop(ruleForm.floorStyle)"> + 选择商品</div>
              </div>
              <div class="list_wrap">
                <div v-for="(item,index) in tijiaoShopList" :key="index" class="item_wrap">
                  <div></div>
                  <div>{{ item.goodsName }}</div>
                  <img src="../../assets/images/zidingyiDel.png" alt="" @click="delShop(index)">
                </div>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle == 22">
              <div class="gg_wrap">
                <el-upload :action="uploadUrl"
                           list-type="picture-card"
                           :on-remove="handleRemove1"
                           :on-success="handleSuccess1">
                  <img v-if="ruleForm.advertisement" :src="ruleForm.advertisement" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="input_wrap1 marginTop">
                  <label class="el-form-item__label">链接地址</label>
                  <div class="el-input--small" style="width: 100%;">
                    <el-input style="width: 200px;cursor: pointer" type="text" placeholder="请输入跳转链接" clearable
                              v-model="ruleForm.guanggaoLink"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle == 23">
              <div class="imgs_wrap">
                <el-upload
                    :action="uploadUrl"
                    :class="{
                  disabled: uploadDisabled,
                  }"
                    list-type="picture-card"
                    :on-remove="handleRemove3"
                    :on-success="handleSuccess3"
                    :file-list="ruleForm.goodPhoto"
                    :limit="100"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="ruleForm.floorType == 1">
          <div class="con_wrap">
            <div class="decorate-center" v-if="ruleForm.floorStyle == 0">
              <div class="left_wrap">
                <div class="banner">
                  <el-carousel height="400px">
                    <el-carousel-item v-for="(item,index) in swiperList" :key="index">
                      <img :src="item.url" alt="">
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
              <div class="attribute-tabs">
                <div class="flex attribute-header">
                  <div class="attribute-title">轮播图</div>
                </div>
                <div class="attribute-content">
                  <div>
                    <form class="el-form el-form--label-left">
                      <div class="attribute-item">
                        <div class="title flex"> 图片设置<span
                            class="muted xs flex-1 m-l-8">最多可添加10张，建议图片尺寸：1020px*440px</span></div>
                        <div class="content1">
                          <div class="nav-content">
                            <div class="nav-list">
                              <div v-for="(item,index) in swiperList" :key="index" style="position: relative"
                                   @mouseenter="toMouseenter1(index)"
                                   @mouseleave="toMouseleave1(index)">
                                <div v-if="item.isDel" @click="del1(index)">
                                  <i class="el-icon-close ls-icon-del"></i>
                                </div>
                                <div class="nav-item ls-del-wrap">
                                  <div class="flexColumn">
                                    <div class="smallImg" @click="toImg(index)">
                                      <el-upload :action="uploadUrl"
                                                 list-type="picture-card"
                                                 :on-remove="handleRemove"
                                                 :on-success="handleSuccess">
                                        <img v-if="item.url" :src="item.url" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                      </el-upload>
                                    </div>
                                    <div class="input_wrap1">
                                      <label class="el-form-item__label">链接地址</label>
                                      <div class="el-input--small" style="width: 100%;" @click="toChooseUrl1(index)">
                                        <el-input style="width: 200px;cursor: pointer" type="text" placeholder="请选择跳转链接"
                                                  clearable
                                                  v-model="item.title"></el-input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="" style="text-align: center;width: 100%" v-if="navlist.length<=10">
                                <el-button type="" style="width: 100%" @click="add1">添加图片</el-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle <= 7&&ruleForm.floorStyle>0">
              <div>
                <div class="style_img">
                  <div class="title_wtap">
                    <div class="choose_title_zdy">
                      <el-input class="input_title" v-model="customize_part" placeholder="请输入栏目名称"></el-input>
                      <el-button class="button_title" type="primary" @click="baocun">保存</el-button>
                    </div>
                    <div class="button_wrap">
                      <div class="button1" @click="addType">
                        <span> + 添加分类</span>
                      </div>
                    </div>
                  </div>
                  <template v-if="ruleForm.floorStyle == 1">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video1.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 2">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video2.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 3">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video3.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 4">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video4.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 5">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video5.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 6">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video6.png" alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 7">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/video7.png" alt="">
                  </template>


                  <div style="display: flex" :style="{justifyContent:ruleForm.floorStyle != 1?'center':'space-between'}"
                       :class="zidingyi_xz == 1?'part_sc_wrap':'part_sc_wrap1'">
                    <div v-if="ruleForm.floorStyle == 1" class="part_sc">
                      <div class="choose_nr_part" @click="chooseNR_part(ruleForm.floorStyle,1)"> + 选择/上传视频</div>
                      <div class="part_xz" v-if="zidingyi_xz == 1">
                        <div @click="zdy_choose">选择视频</div>
                        <div @click="zdy_upload(1)">上传视频</div>
                      </div>
                    </div>

                    <div class="part_sc">
                      <div class="choose_nr_part" @click="chooseNR_part(ruleForm.floorStyle,0)"> + 选择/上传视频</div>
                      <div class="part_xz" v-if="zidingyi_xz == 2">
                        <div @click="zdy_choose">选择内容</div>
                        <div @click="zdy_upload(2)">上传内容</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle > 7&&ruleForm.floorStyle<=17">
              <div>
                <div class="style_img">
                  <div class="title_wtap">
                    <div class="choose_title_zdy">
                      <el-input class="input_title" v-model="customize_part" placeholder="请输入栏目名称"></el-input>
                      <el-button class="button_title" type="primary" @click="baocun">保存</el-button>
                    </div>
                    <div class="button_wrap">
                      <div class="button1" @click="addType">
                        <span> + 添加分类</span>
                      </div>
                    </div>
                  </div>
                  <template v-if="ruleForm.floorStyle == 8">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article1.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 9">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article2.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 10">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article3.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 11">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article4.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 12">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article5.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 13">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article6.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 14">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article7.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 15">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article8.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 16">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article9.png"
                         alt="">
                  </template>
                  <template v-if="ruleForm.floorStyle == 17">
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/article10.png"
                         alt="">
                  </template>
                </div>

                <div style="display: flex" :style="{justifyContent:ruleForm.floorStyle != 8?'center':'space-between'}"
                     :class="zidingyi_xz == 1?'part_sc_wrap':'part_sc_wrap1'">
                  <div v-if="ruleForm.floorStyle == 8" class="part_sc">
                    <div class="choose_nr_part" @click="chooseNR_part(ruleForm.floorStyle,1)"> + 选择/上传内容</div>
                    <div class="part_xz" v-if="zidingyi_xz == 1">
                      <div @click="zdy_choose">选择内容</div>
                      <div @click="zdy_upload(1)">上传内容</div>
                    </div>
                  </div>

                  <div class="part_sc">
                    <div class="choose_nr_part" @click="chooseNR_part(ruleForm.floorStyle,0)"> + 选择/上传内容</div>
                    <div class="part_xz" v-if="zidingyi_xz == 2">
                      <div @click="zdy_choose">选择内容</div>
                      <div @click="zdy_upload(2)">上传内容</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle > 17&&ruleForm.floorStyle<=20">
              <div style="display: flex">
                <template v-if="ruleForm.floorStyle == 18">
                  <div v-for="(item,index) in zhuanTi1" :key="index" style="position: relative;"
                       @click="toZTImg(index)">
                    <div class="zt1_wrap">
                      <el-upload :action="uploadUrl"
                                 list-type="picture-card"
                                 :on-remove="handleRemove2"
                                 :on-success="handleSuccess2">
                        <img v-if="item.jumpUrl" :src="item.jumpUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="input_wrap1 marginTop">
                        <label class="el-form-item__label">链接地址</label>
                        <div class="el-input--small" style="width: 100%;" @click="toChooseztUrl(index)">
                          <el-input style="width: 200px;cursor: pointer" type="text" placeholder="请选择跳转链接"
                                    clearable
                                    v-model="item.title"></el-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="ruleForm.floorStyle == 19">
                  <div v-for="(item,index) in zhuanTi2" :key="index" style="position: relative;margin-right: 20px"
                       @click="toZTImg(index)">
                    <div class="zt2_wrap">
                      <el-upload :action="uploadUrl"
                                 list-type="picture-card"
                                 :on-remove="handleRemove2"
                                 :on-success="handleSuccess2">
                        <img v-if="item.jumpUrl" :src="item.jumpUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="input_wrap1 marginTop">
                        <label class="el-form-item__label">链接地址</label>
                        <div class="el-input--small" style="width: 100%;" @click="toChooseztUrl(index)">
                          <el-input style="width: 200px;cursor: pointer" type="text" placeholder="请选择跳转链接"
                                    clearable
                                    v-model="item.title"></el-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="ruleForm.floorStyle == 20">
                  <div v-for="(item,index) in zhuanTi3" :key="index" style="position: relative;margin-right: 20px"
                       @click="toZTImg(index)">
                    <div class="zt3_wrap">
                      <el-upload :action="uploadUrl"
                                 list-type="picture-card"
                                 :on-remove="handleRemove2"
                                 :on-success="handleSuccess2">
                        <img v-if="item.jumpUrl" :src="item.jumpUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="input_wrap1 marginTop">
                        <label class="el-form-item__label">链接地址</label>
                        <div class="el-input--small" style="width: 100%;" @click="toChooseztUrl(index)">
                          <el-input style="width: 200px;cursor: pointer" type="text" placeholder="请选择跳转链接"
                                    clearable
                                    v-model="item.title"></el-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle == 21">
              <div>
                <div class="style_img">
                  <template>
                    <img class="img"
                         src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/one-stand/building/shopStyle.png"
                         alt="">
                  </template>
                </div>
                <div class="choose_nr" @click="chooseShop(ruleForm.floorStyle)"> + 选择商品</div>
              </div>
              <div class="list_wrap">
                <div v-for="(item,index) in tijiaoShopList" :key="index" class="item_wrap">
                  <div></div>
                  <div>{{ item.goodsName }}</div>
                  <img src="../../assets/images/zidingyiDel.png" alt="" @click="delShop(index)">
                </div>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle == 22">
              <div class="gg_wrap">
                <el-upload :action="uploadUrl"
                           list-type="picture-card"
                           :on-remove="handleRemove1"
                           :on-success="handleSuccess1">
                  <img v-if="ruleForm.advertisement" :src="ruleForm.advertisement" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="input_wrap1 marginTop">
                  <label class="el-form-item__label">链接地址</label>
                  <div class="el-input--small" style="width: 100%;">
                    <el-input style="width: 200px;cursor: pointer" type="text" placeholder="请输入跳转链接" clearable
                              v-model="ruleForm.guanggaoLink"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="decorate-center" v-if="ruleForm.floorStyle == 23">
              <div class="imgs_wrap">
                <el-upload
                    :action="uploadUrl"
                    :class="{
                  disabled: uploadDisabled,
                  }"
                    list-type="picture-card"
                    :on-remove="handleRemove3"
                    :on-success="handleSuccess3"
                    :file-list="ruleForm.goodPhoto"
                    :limit="100"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
              class="save"
              type="primary"
              @click="submitForm('ruleForm')"
          >保存
          </el-button
          >
        </el-form-item>
      </el-form>

      <div class="right_list_wrap" v-if="ruleForm.floorType == 1&&(ruleForm.floorStyle <= 7&&ruleForm.floorStyle>0)">
        <div class="list_wrap" v-if="dataInfo.workModelList.length">
          <div v-for="(item,index) in dataInfo.workModelList" :key="index" class="item_wrap">
            <div>{{ item.name }}</div>
            <div class="icon_wrap">
              <img v-if="item.dataFrom == 'NEW'" src="../../assets/images/update.png" alt=""
                   @click="update_add_wz(item,1)">
              <img src="../../assets/images/zidingyiDel.png" alt="" @click="del_add_wz(item)">
            </div>
          </div>
        </div>
        <template v-if="dataInfo.assWebsiteStoreyOutTypeModelList.length">
          <div class="fenlei_list_wrap" v-for="(item,index) in dataInfo.assWebsiteStoreyOutTypeModelList" :key="index">
            <div class="fenlei_wrap">
              <div class="fenlei_title">{{ item.typeName }}</div>
              <div class="fenlei_button">
                <div class="button" @click="update_add_type(item)">
                  <img src="../../assets/images/update.png" alt="">
                  <span>修改</span>
                </div>
                <div class="button" @click="del_add_type(item)">
                  <img src="../../assets/images/update.png" alt="">
                  <span>删除</span>
                </div>
              </div>
            </div>
            <div class="list_wrap">
              <div v-for="(item1,index1) in item.workModelList" :key="index1" class="item_wrap">
                <div>{{ item1.name }}</div>
                <div class="icon_wrap">
                  <img v-if="item1.dataFrom == 'NEW'" src="../../assets/images/update.png" alt=""
                       @click="update_add_wz(item1,2)">
                  <img src="../../assets/images/zidingyiDel.png" alt="" @click="del_add_wz(item1)">
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="right_list_wrap" v-if="ruleForm.floorType == 1&&(ruleForm.floorStyle > 7&&ruleForm.floorStyle<=17)">
        <div class="list_wrap" v-if="dataInfo.workModelList.length">
          <div v-for="(item,index) in dataInfo.workModelList" :key="index" class="item_wrap">
            <div>{{ item.name }}</div>
            <div class="icon_wrap">
              <img v-if="item.dataFrom == 'NEW'" src="../../assets/images/update.png" alt=""
                   @click="update_add_wz(item,1)">
              <img src="../../assets/images/zidingyiDel.png" alt="" @click="del_add_wz(item)">
            </div>
          </div>
        </div>
        <template v-if="dataInfo.assWebsiteStoreyOutTypeModelList.length">
          <div class="fenlei_list_wrap" v-for="(item,index) in dataInfo.assWebsiteStoreyOutTypeModelList" :key="index">
            <div class="fenlei_wrap">
              <div class="fenlei_title">{{ item.typeName }}</div>
              <div class="fenlei_button">
                <div class="button" @click="update_add_type(item)">
                  <img src="../../assets/images/update.png" alt="">
                  <span>修改</span>
                </div>
                <div class="button" @click="del_add_type(item)">
                  <img src="../../assets/images/update.png" alt="">
                  <span>删除</span>
                </div>
              </div>
            </div>
            <div class="list_wrap">
              <div v-for="(item1,index1) in item.workModelList" :key="index1" class="item_wrap">
                <div>{{ item1.name }}</div>
                <div class="icon_wrap">
                  <img v-if="item1.dataFrom == 'NEW'" src="../../assets/images/update.png" alt=""
                       @click="update_add_wz(item1,2)">
                  <img src="../../assets/images/zidingyiDel.png" alt="" @click="del_add_wz(item1)">
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="right_list_wrap" v-if="ruleForm.floorType == 0&&(ruleForm.floorStyle <= 7&&ruleForm.floorStyle>0)">
        <div style="border-bottom: none" class="list_wrap1">
          <div v-for="(item,index) in tijiaoArcicleList" :key="index" class="item_wrap">
            <div></div>
            <div>{{ item.name }}</div>
            <img src="../../assets/images/zidingyiDel.png" alt="" @click="del(index)">
          </div>
        </div>
      </div>
      <div class="right_list_wrap" v-if="ruleForm.floorType == 0&&(ruleForm.floorStyle > 7&&ruleForm.floorStyle<=17)">
        <div style="border-bottom: none" class="list_wrap1">
          <div v-for="(item,index) in tijiaoArcicleList" :key="index" class="item_wrap">
            <div></div>
            <div>{{ item.name }}</div>
            <img src="../../assets/images/zidingyiDel.png" alt="" @click="del(index)">
          </div>
        </div>
      </div>
    </div>


    <el-dialog
        title="选择跳转链接"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <div class="tanchaung">
        <el-tabs v-model="activeName">
          <el-tab-pane label="内部页面" name="first">
            <!--            <ul class="nav-list">-->
            <!--              <div v-for="(item, index) in urlList" :key="index" @click="toTC(index)">-->
            <!--                <li class="nav-item b-s" :class="index==tcActiveIndex?'active':''">{{ item.title }}</li>-->
            <!--              </div>-->
            <!--            </ul>-->
            <div class="jump_wrap">
              <div class="title">跳转链接</div>
              <el-input style="width: 300px;align-self: center" v-model="jumpUrl" placeholder="请输入跳转链接"></el-input>
              <div style="align-self: center;color: red;margin-left: 10px">例：dynamicDetail5?workId=**，id是文章或者视频id</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="自定义链接" name="second">
            <div class="jump_wrap">
              <div class="title">跳转链接</div>
              <el-input style="width: 300px;align-self: center" v-model="jumpUrl" placeholder="请输入跳转链接"></el-input>
            </div>
          </el-tab-pane>
        </el-tabs>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toClick">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="选择文章/视频"
        :visible.sync="centerDialogVisible_choose"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close1"
    >
      <div class="tanchaung1">
        <ul class="nav-list">
          <div v-for="(item, index) in typeList" :key="index" @click="toLX(index)">
            <li class="nav-item b-s" :class="index==typeIndex?'active':''">{{ item.name }}</li>
          </div>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_choose = false">取 消</el-button>
        <el-button type="primary" @click="toClickType">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="选择文章/视频"
        :visible.sync="centerDialogVisible_con"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="centerDialogVisible_con = false"
    >
      <div>
        <div></div>
        <commonTable
            :tableData="tableData"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            @handleSelectionChange="handleSelectionChange"
            :currentPage="currentPage"
            :loading="loading"
            :total="total"
        >
          <template v-slot:table>
            <el-table-column type="selection" width="50"/>
            <el-table-column prop="name" align="center" label="标题"/>
            <el-table-column prop="createTime" align="center" label="创建时间"/>
          </template>
        </commonTable>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_con = false">取 消</el-button>
        <el-button type="primary" @click="toClickCon">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="选择商品"
        :visible.sync="centerDialogVisible_shop"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close3"
    >
      <div>
        <div></div>
        <commonTable
            :tableData="tableData1"
            @handleSizeChange="handleSizeChange1"
            @handleCurrentChange="handleCurrentChange1"
            @handleSelectionChange="handleSelectionChange1"
            :currentPage="currentPage1"
            :loading="loading1"
            :total="total1"
        >
          <template v-slot:table>
            <el-table-column type="selection" width="50"/>
            <el-table-column prop="goodsName" align="center" label="商品名称"/>
            <el-table-column prop="goodsTypeName" align="center" label="分类"/>
            <el-table-column prop="createTime" align="center" label="发布时间"/>
          </template>
        </commonTable>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_shop = false">取 消</el-button>
        <el-button type="primary" @click="toClickShop">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="选择跳转链接"
        :visible.sync="centerDialogVisible_zt"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="centerDialogVisible_zt = false"
    >
      <div class="tanchaung">
        <el-tabs v-model="activeZT">
          <el-tab-pane label="内部页面" name="first">
            <ul class="nav-list">
              <div v-for="(item, index) in ztList" :key="index" @click="toZT(index)">
                <li class="nav-item b-s" :class="index==tcActiveZTIndex?'active':''">{{ item.pageName }}</li>
              </div>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="自定义链接" name="second">
            <div class="jump_wrap">
              <div class="title">跳转链接</div>
              <el-input style="width: 300px;align-self: center" v-model="jumpUrlZT" placeholder="请输入跳转链接"></el-input>
            </div>
          </el-tab-pane>
        </el-tabs>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_zt = false">取 消</el-button>
        <el-button type="primary" @click="toClickZT">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :title="typeTitle"
        :visible.sync="centerDialogVisible_type"
        :modal-append-to-body="false"
        width="30%"
        center
        @close="close_part"
    >
      <el-form
          :model="ruleForm_fl"
          :rules="rules_fl"
          ref="ruleForm_fl"
          label-width="0"
      >
        <el-form-item label="" prop="title">
          <div class="flex">
            <el-input clearable v-model="ruleForm_fl.title" maxlength="50" show-word-limit></el-input>
            <el-button type="primary" class="ml10" @click="determine">保 存</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="!update_type_edit">
          <div class="flex reclassify_list">
            <div class="reclassify_item" v-for="(item,index) in smallList" :key="index">
              {{ item.typeName }}
              <i class="el-icon-close close_z" @click="delSmall(item.typeId)"></i>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_type = false">关 闭</el-button>
        <!--         <el-button type="primary" @click="part_erji">确 定</el-button>-->
      </span>
    </el-dialog>
    <el-dialog
        title="发布"
        :visible.sync="centerDialogVisible_add"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close_add"
    >
      <el-form
          :model="ruleForm_add"
          :rules="rules_add"
          ref="ruleForm_add"
          label-width="150px"
      >
        <el-form-item label="文章名称" prop="title">
          <el-input clearable v-model="ruleForm_add.title" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="分类" prop="type" v-if="uploadType == 2">
          <el-select
              clearable
              v-model="ruleForm_add.type"
              style="width: 100%"
          >
            <el-option
                v-for="(item) in smallList"
                :key="item.typeId"
                :label="item.typeName"
                :value="item.typeId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input clearable v-model="ruleForm_add.sort" min="0"
                    @input="ruleForm_add.sort=ruleForm_add.sort.replace(/^0(0+)|[^\d]+/g,'')" maxlength="11"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item :label="ruleForm.floorStyle <= 7&&ruleForm.floorStyle>0?'封面':'图片'" prop="coverImg">
          <el-upload :action="$store.state.uploadingUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled_add }"
                     :on-remove="handleRemove_add"
                     :on-success="handleSuccess_add"
                     :before-upload="beforeAvatarUploadImg"
                     :file-list="fileList_add"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              <div slot="tip" class="el-upload__tip">
                图片上传比例为5:3，支持格式png、jpg、jpeg，大小不超过1M
              </div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频内容" prop="videoUrl" v-if="ruleForm.floorStyle <= 7&&ruleForm.floorStyle>0">
          <el-upload
              class="avatar-uploader"
              :action="$store.state.uploadingUrl"
              :show-file-list="false"
              accept=".mp4"
              :on-progress="handProgress"
              :on-success="handleAvatarSuccessVideo"
              :before-upload="beforeAvatarUploadVideo"
          >
            <video
                v-if="ruleForm_add.videoUrl"
                :src="ruleForm_add.videoUrl"
                width="100%"
                height="100%"
                controls
            >
              <source src="movie.mp4" type="video/mp4"/>
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过20M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容" prop="details">
          <quill-editor
              @ready="ready($event)"
              style="height: 100%"
              v-model="ruleForm_add.details"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange($event)"
          />
          <span class="wordNumber">{{ TiLength }}/8000</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_add = false">取 消</el-button>
        <el-button type="primary" @click="add_WZ">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="选择文章/视频"
        :visible.sync="centerDialogVisible_zdy_choose"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close_add"
    >
      <div>
        <div class="leixing_wrap">
          <div class="leixing_title">类型</div>
          <el-select
              style="flex: 1"
              class="mr10"
              v-model="filterType"
              clearable
          >
            <el-option
                v-for="(item) in smallList"
                :key="item.typeId"
                :label="item.typeName"
                :value="item.typeId"
            />
          </el-select>
        </div>
        <div class="mt10">
          <el-input
              style="width: 390px!important;"
              class="w120 mr10"
              placeholder="名称"
              v-model="filterName"
              clearable
          />
          <el-button type="primary" @click="filterNames">搜索</el-button>
        </div>
        <commonTable
            :tableData="tableData_add"
            @handleSizeChange="handleSizeChange_add"
            @handleCurrentChange="handleCurrentChange_add"
            @handleSelectionChange="handleSelectionChange_add"
            :currentPage="currentPage_add"
            :loading="loading_add"
            :total="total_add"
        >
          <template v-slot:table>
            <el-table-column type="selection" width="50"/>
            <el-table-column prop="name" align="center" label="标题"/>
            <el-table-column prop="createTime" align="center" label="创建时间"/>
          </template>
        </commonTable>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_zdy_choose = false">取 消</el-button>
        <el-button type="primary" @click="toAddOld">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {lineHeightStyle} from "@/utils/lineHeight.js";
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

quillConfig.placeholder = '请输入品内容'

import commonTable from "@/components/common/commonTable";
import {
  queryAssWebCustomData,
  queryAssBannerForWebSite,
  addAssWebsiteStorey,
  queryAssWebsiteStoreyById,
  updateAssWebsiteStorey,
  queryArticleTypeUse,
  selectWorkPageList,
  getGoodsInfoPC,
  queryCustomPageAll,
  addAssWebsiteStoreyOutType,
  queryAssWebsiteStoreyOutTypeById,
  updateAssWebsiteStoreyOutType,
  queryAssWebsiteStoreyOutType,
  delAssWebsiteStoreyOutType,
  addAssWebsiteStoreyOutData,
  updateAssWebsiteStoreyOutData,
  queryOutTypeData,
  delAssWebsiteStoreyOutData,
  addBindOldDataByType,
  cleanDataTypeByBigType
} from "@/api/website";
import {mapState} from "vuex";

export default {
  name: "addfloor",
  components: {
    commonTable,
  },
  data() {
    return {
      customize_part: '',
      centerDialogVisible_type: false,
      ruleForm_fl: {
        title: '',
        typeId: ''
      },
      rules_fl: {
        title: [{required: true, message: "请输入分类名称", trigger: "blur"}],
      },
      zidingyi_xz: 0,
      centerDialogVisible_add: false,
      TiLength: 0,
      editorOption: quillConfig,
      ruleForm_add: {
        title: "",
        type: "",
        sort: "",
        coverImg: '',
        details: "",
        videoUrl: ""
      },
      rules_add: {
        title: [{required: true, message: "请输入名称", trigger: "blur"}],
        coverImg: [{required: true, message: "请上传图片"}],
        sort: [{required: true, message: "请输入排序", trigger: "blur"}],
        details: [{required: true, message: "请输入内容", trigger: "blur"}],
      },
      fileList_add: [],
      smallList: [],
      editUpdate: false,
      dataInfo: {
        workModelList: [],
        assWebsiteStoreyOutTypeModelList: []
      },
      workId: '',
      typeTitle: '添加分类',
      update_type_edit: false,
      centerDialogVisible_zdy_choose: false,
      currentPage_add: 0,
      loading_add: false,
      total_add: 0,
      tableData_add: [],
      filterName: '',
      filterType: '',
      multipleSelection_add: [],
      uploadType: 1,
      ifJY: false,
      ifXG: false,


      jumpUrlZT: '',
      tcActiveZTIndex: 0,
      activeZT: 'first',
      centerDialogVisible_zt: false,
      ztList: [],
      inputIndexZT: 0,
      zhuanTi1: [{}],
      zhuanTi2: [{}, {}],
      zhuanTi3: [{}, {}, {}],
      multipleSelection: [],
      tijiaoArcicleList: [],
      workType: 0,
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数

      ruleForm: {
        floorName: "",
        radio: 0,
        sort: "",
        floorStyle: null,
        floorType: 0,
        advertisement: '',
        goodPhoto: [],
        guanggaoLink: ''
      },
      rules: {
        floorName: [{required: true, message: "请输入楼层名称", trigger: "blur"}],
        sort: [{required: true, message: "请输入排序", trigger: "blur"}],
        radio: [{required: true, message: "请选择状态", trigger: "blur"}],
        floorStyle: [{required: true, message: "请选择楼层样式", trigger: "blur"}],
        floorType: [{required: true, message: "请选择楼层类型", trigger: "blur"}],
      },
      jumpUrl: '',
      activeName: 'first',
      typeIndex: 0,
      tcActiveIndex: 0,
      activeIndex: 0,
      text: '',
      navlist: [],
      activeGL: 0,
      centerDialogVisible: false,
      centerDialogVisible_choose: false,
      centerDialogVisible_con: false,
      urlList: [],
      swiperList: [],
      uploadUrl: this.$store.state.uploadingUrl,
      inputIndex: 0,
      inputIndex1: 0,
      ossUrl: this.$store.state.ossUrl,
      title: '+ 选择类型',

      options: [
        {
          value: 0,
          label: '轮播图'
        }, {
          value: 1,
          label: '视频样式一'
        }, {
          value: 2,
          label: '视频样式二'
        }, {
          value: 3,
          label: '视频样式三'
        }, {
          value: 4,
          label: '视频样式四'
        }, {
          value: 5,
          label: '视频样式五'
        }, {
          value: 6,
          label: '视频样式六'
        }, {
          value: 7,
          label: '视频样式七'
        }, {
          value: 8,
          label: '文章样式一'
        }, {
          value: 9,
          label: '文章样式二'
        }, {
          value: 10,
          label: '文章样式三'
        }, {
          value: 11,
          label: '文章样式四'
        }, {
          value: 12,
          label: '文章样式五'
        }, {
          value: 13,
          label: '文章样式六'
        }, {
          value: 14,
          label: '文章样式七'
        }, {
          value: 15,
          label: '文章样式八'
        }, {
          value: 16,
          label: '文章样式九'
        }, {
          value: 17,
          label: '文章样式十'
        }, {
          value: 18,
          label: '专题样式一'
        }, {
          value: 19,
          label: '专题样式二'
        }, {
          value: 20,
          label: '专题样式三'
        }, {
          value: 21,
          label: '商品'
        }, {
          value: 22,
          label: '广告图'
        }, {
          value: 23,
          label: '展示图'
        }],
      typeList: [],
      fileList1: [],
      classType: '',
      centerDialogVisible_shop: false,
      tableData1: [],
      currentPage1: 1, //当前页
      pageSize1: 10, //显示条数
      loading1: false, //表格加载
      total1: 0, //总条数
      multipleSelection1: [],
      tijiaoShopList: [],
    }
  },
  mounted() {
    console.log(localStorage.getItem('typeId'))
    if (this.$route.query.storeyId) {
      this.Detail()
      this.ifXG = true
    }
    if (localStorage.getItem('typeId') && this.ruleForm.floorStyle) {
      this.getAddType()
      this.getAddWzList()
      this.getSmallType()
    }
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.goodPhoto.length >= 100;
    },
    uploadDisabled_add() {
      return this.fileList_add.length !== 0;
    },
  },
  methods: {
    ready() {
      Quill.register({'formats/lineHeight': lineHeightStyle}, true);
    },
    onEditorChange(e) {
      if (this.ruleForm.details == '') {
        this.TiLength = 0
      } else {
        this.TiLength = e.quill.getLength() - 1
      }
    },
    del(index) {
      this.tijiaoArcicleList.splice(index, 1)
    },
    async queryPage() {
      let data = {
        associationId: localStorage.getItem("associationId"),
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        workType: this.workType,
        type: 1,
        classType: this.classType,
        fatherIdAll: 1
      };
      try {
        this.loading = true;
        const result = await selectWorkPageList(data);
        console.log(result)
        if (result.data) {
          this.loading = false;
          const {total, list} = result.data.pageInfo;
          for (let i in list) {
            list[i].createTime = this.renderTime(list[i].createTime)
          }
          this.tableData = list;
          this.total = total;
          this.centerDialogVisible_con = true
        } else {
          this.$message.error(result.message)
        }
      } catch (error) {
        console.log(error)
        this.tableData = []
        this.loading = false;
      }
    },
    async queryPage1() {
      let data = {
        associationId: localStorage.getItem("associationId"),
        pageSize: this.pageSize1,
        pageNum: this.currentPage1,
      };
      try {
        this.loading1 = true;
        const result = await getGoodsInfoPC(data);
        if (result.data) {
          this.loading1 = false;
          let val = JSON.parse(result.data.pageInfo);
          for (let i in val.list) {
            val.list[i].createTime = this.renderTime(val.list[i].createTime)
          }
          this.tableData1 = val.list
          this.total1 = val.total
          this.centerDialogVisible_shop = true
        } else {
          this.$message.error(result.message)
        }
      } catch (error) {
        console.log(error)
        this.tableData1 = []
        this.loading1 = false;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.queryPage1();
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.queryPage1();
    },
    handleSelectionChange1(val) {
      this.multipleSelection1 = val;
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    chooseType(num, bz) {
      let data = {}
      if (num <= 7) {
        data = {
          associationId: localStorage.getItem("associationId"),
          identificaType: 4
        }
      } else {
        data = {
          associationId: localStorage.getItem("associationId"),
          fatherId: 0,
          identificaType: 1
        }
      }
      queryArticleTypeUse(data).then(res => {
        if (res.code == '200') {
          this.typeList = res.data
          this.centerDialogVisible_choose = true
        }
      })
    },
    chooseNR(num, bz) {
      if (num <= 7) {
        this.workType = 1
      } else {
        this.workType = 0
      }
      if (this.classType) {
        this.queryPage()
      } else {
        this.$message.error('请选择类型')
      }
    },
    chooseShop(num, bz) {
      this.queryPage1()
    },
    deleteType() {
      this.title = '+ 选择类型'
      this.classType = ''
      this.tijiaoArcicleList = []
    },
    Detail() {
      let data = {
        storeyId: this.$route.query.storeyId
      }
      queryAssWebsiteStoreyById(data).then(res => {
        if (res.code == '200') {
          this.ruleForm.floorName = res.data.storeyName
          this.ruleForm.floorStyle = res.data.storeyPattern
          this.ruleForm.floorType = Number(res.data.storeyType) - 1
          if (res.data.storeyPattern == 0) {
            this.swiperList = JSON.parse(res.data.content)
          } else if (res.data.storeyPattern == 1 || res.data.storeyPattern == 2 || res.data.storeyPattern == 3 || res.data.storeyPattern == 4 || res.data.storeyPattern == 5 || res.data.storeyPattern == 6 || res.data.storeyPattern == 7) {
            if (res.data.storeyType == 1) {
              this.tijiaoArcicleList = res.data.workModelList
              this.title = res.data.workModelList[0].fatherName ? res.data.workModelList[0].fatherName : res.data.workModelList[0].typeName
              this.classType = res.data.workModelList[0].fatherId ? res.data.workModelList[0].fatherId : res.data.workModelList[0].classType
            } else {
              if (res.data.bigTypeModel.typeId) {
                this.customize_part = res.data.bigTypeModel.typeName
                localStorage.setItem('typeId', res.data.bigTypeModel.typeId)
                this.ifJY = true
                this.getAddType()
                this.getAddWzList()
                this.getSmallType()
              }
            }
          } else if (res.data.storeyPattern == 8 || res.data.storeyPattern == 9 || res.data.storeyPattern == 10 || res.data.storeyPattern == 11 || res.data.storeyPattern == 12 || res.data.storeyPattern == 13 || res.data.storeyPattern == 14 || res.data.storeyPattern == 15 || res.data.storeyPattern == 16 || res.data.storeyPattern == 17) {
            if (res.data.storeyType == 1) {
              this.tijiaoArcicleList = res.data.workModelList
              this.title = res.data.workModelList[0].fatherName ? res.data.workModelList[0].fatherName : res.data.workModelList[0].typeName
              this.classType = res.data.workModelList[0].fatherId ? res.data.workModelList[0].fatherId : res.data.workModelList[0].classType
            } else {
              if (res.data.bigTypeModel.typeId) {
                this.customize_part = res.data.bigTypeModel.typeName
                localStorage.setItem('typeId', res.data.bigTypeModel.typeId)
                this.ifJY = true
                this.getAddType()
                this.getAddWzList()
                this.getSmallType()
              }
            }
          } else if (res.data.storeyPattern == 18) {
            this.zhuanTi1 = JSON.parse(res.data.content)
          } else if (res.data.storeyPattern == 19) {
            this.zhuanTi2 = JSON.parse(res.data.content)
          } else if (res.data.storeyPattern == 20) {
            this.zhuanTi3 = JSON.parse(res.data.content)
          } else if (res.data.storeyPattern == 21) {
            this.tijiaoShopList = res.data.goodsList
          } else if (res.data.storeyPattern == 22) {
            let content = JSON.parse(res.data.content)
            this.ruleForm.advertisement = content.img
            this.ruleForm.guanggaoLink = content.jump
          } else if (res.data.storeyPattern == 23) {
            this.ruleForm.goodPhoto = JSON.parse(res.data.content)
          }
          this.ruleForm.radio = res.data.status
          this.ruleForm.sort = res.data.sort
        }
      })
    },
    async toChooseUrl1(index) {
      let data = {};
      this.centerDialogVisible = true
      const result = await queryAssWebCustomData(data);
      this.urlList = result.data;
      this.inputIndex1 = index
    },
    async toChooseztUrl(index) {
      let data = {
        associationId: localStorage.getItem("associationId"),
      };
      this.centerDialogVisible_zt = true
      const result = await queryCustomPageAll(data);
      this.ztList = result.data;
      this.inputIndexZT = index
    },
    close() {
      this.centerDialogVisible = false
    },
    close1() {
      this.centerDialogVisible_choose = false
    },
    close2() {
      this.centerDialogVisible_con = false
    },
    close3() {
      this.centerDialogVisible_shop = false
    },
    toTC(index) {
      this.tcActiveIndex = index
    },
    toZT(index) {
      this.tcActiveZTIndex = index
    },
    toLX(index) {
      this.typeIndex = index
    },
    toClick() {
      if (this.activeName == 'first') {
        // this.swiperList[this.inputIndex1].jumpUrl = this.urlList[this.tcActiveIndex].url
        // this.swiperList[this.inputIndex1]['title'] = this.urlList[this.tcActiveIndex].title

        this.swiperList[this.inputIndex1].jumpUrl = this.jumpUrl
        this.swiperList[this.inputIndex1]['title'] = this.jumpUrl
        this.swiperList[this.inputIndex1]['type'] = 1
      } else {
        this.swiperList[this.inputIndex1].jumpUrl = this.jumpUrl
        this.swiperList[this.inputIndex1]['title'] = this.jumpUrl
        this.swiperList[this.inputIndex1]['type'] = 2
      }
      this.centerDialogVisible = false
      this.jumpUrl = ''
    },
    toClickZT() {
      if (this.activeZT == 'first') {
        if (this.ruleForm.floorStyle == 18) {
          this.zhuanTi1[this.inputIndexZT]['title'] = this.ztList[this.tcActiveZTIndex].pageName
          this.zhuanTi1[this.inputIndexZT]['dataId'] = this.ztList[this.tcActiveZTIndex].dataId
          this.zhuanTi1[this.inputIndexZT]['typeState'] = this.ztList[this.tcActiveZTIndex].typeState
        } else if (this.ruleForm.floorStyle == 19) {
          this.zhuanTi2[this.inputIndexZT]['title'] = this.ztList[this.tcActiveZTIndex].pageName
          this.zhuanTi2[this.inputIndexZT]['dataId'] = this.ztList[this.tcActiveZTIndex].dataId
          this.zhuanTi2[this.inputIndexZT]['typeState'] = this.ztList[this.tcActiveZTIndex].typeState
        } else {
          this.zhuanTi3[this.inputIndexZT]['title'] = this.ztList[this.tcActiveZTIndex].pageName
          this.zhuanTi3[this.inputIndexZT]['dataId'] = this.ztList[this.tcActiveZTIndex].dataId
          this.zhuanTi3[this.inputIndexZT]['typeState'] = this.ztList[this.tcActiveZTIndex].typeState
        }
      } else {
        if (this.ruleForm.floorStyle == 18) {
          this.zhuanTi1[this.inputIndexZT]['title'] = this.jumpUrlZT
          this.zhuanTi1[this.inputIndexZT]['url'] = this.jumpUrlZT
        } else if (this.ruleForm.floorStyle == 19) {
          this.zhuanTi2[this.inputIndexZT]['title'] = this.jumpUrlZT
          this.zhuanTi2[this.inputIndexZT]['url'] = this.jumpUrlZT
        } else {
          this.zhuanTi3[this.inputIndexZT]['title'] = this.jumpUrlZT
          this.zhuanTi3[this.inputIndexZT]['url'] = this.jumpUrlZT
        }
      }
      this.centerDialogVisible_zt = false
      this.jumpUrlZT = ''
    },
    toClickType() {
      this.title = this.typeList[this.typeIndex].name
      this.classType = this.typeList[this.typeIndex].articleTypeId
      this.centerDialogVisible_choose = false
      this.tijiaoArcicleList = []
    },
    toClickCon() {
      let a = this.tijiaoArcicleList.concat(this.multipleSelection)
      let map = new Map()
      let qc = a.filter(key => !map.has(key.workId) && map.set(key.workId, 1)) // 这里对name属性进行去重
      this.tijiaoArcicleList = qc
      this.centerDialogVisible_con = false
    },
    toClickShop() {
      let a = this.tijiaoShopList.concat(this.multipleSelection1)
      let map = new Map()
      let qc = a.filter(key => !map.has(key.goodsId) && map.set(key.goodsId, 1)) // 这里对name属性进行去重
      this.tijiaoShopList = qc
      this.centerDialogVisible_shop = false
    },
    del1(index) {
      this.swiperList.splice(index, 1)
    },
    delShop(index) {
      this.tijiaoShopList.splice(index, 1)
    },
    toImg(index) {
      this.inputIndex1 = index
    },
    add1() {
      this.swiperList.push({})
    },
    toMouseenter1(index) {
      this.swiperList[index].isDel = true
      this.$forceUpdate()
    },
    toMouseleave1(index) {
      this.swiperList[index].isDel = false
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.ruleForm.qrCode = ''
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.swiperList[this.inputIndex1] = {
        isDel: false,
        url: this.ossUrl + file.response.data
      }
      this.$forceUpdate()
    },

    handleRemove1(file, fileList) {
      this.ruleForm.advertisement = ''
    },
    handleSuccess1(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.advertisement = this.ossUrl + file.response.data
    },


    handleRemove2(file, fileList) {
      this.fileList = []
    },
    handleSuccess2(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      if (this.ruleForm.floorStyle == 18) {
        this.zhuanTi1[this.inputIndexZT]['jumpUrl'] = this.ossUrl + file.response.data
      } else if (this.ruleForm.floorStyle == 19) {
        this.zhuanTi2[this.inputIndexZT]['jumpUrl'] = this.ossUrl + file.response.data
      } else if (this.ruleForm.floorStyle == 20) {
        this.zhuanTi3[this.inputIndexZT]['jumpUrl'] = this.ossUrl + file.response.data
      }
      this.$forceUpdate()
    },
    toZTImg(index) {
      this.inputIndexZT = index
    },

    handleRemove3(file, fileList) {
      this.ruleForm.goodPhoto = fileList;
    },
    handleSuccess3(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.ruleForm.goodPhoto = arr;
    },

    submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let data = {
            associationId: localStorage.getItem("associationId"),
            pageId: this.$route.query.pageId,
            storeyName: this.ruleForm.floorName,
            storeyPattern: this.ruleForm.floorStyle,
            status: this.ruleForm.radio,
            sort: this.ruleForm.sort,
            storeyType: this.ruleForm.floorType + 1
          };
          if (this.ruleForm.floorStyle <= 7) {
            if (this.ruleForm.floorStyle > 0) {
              if (this.ruleForm.floorType == 0) {
                if (!this.title) {
                  this.$message.error('请选择类型')
                  return
                } else {
                  if (!this.tijiaoArcicleList.length) {
                    this.$message.error('请选择视频')
                    return
                  } else {
                    data.title = this.title
                    data.dataIds = this.tijiaoArcicleList.map(item => item.workId).join(',')
                    data.dataType = 'WORK_VIDEO'
                  }
                }
              } else {
                if (this.dataInfo.assWebsiteStoreyOutTypeModelList.length || this.dataInfo.workModelList.length) {
                  data.assWebsiteStoreyOutTypeModel = {
                    typeId: localStorage.getItem('typeId'),
                    typeName: this.customize_part
                  }
                  data.dataType = 'WORK_VIDEO'
                } else {
                  this.$message.error('请选择视频或上传视频')
                  return
                }
              }
            } else {
              if (this.swiperList.length) {
                data.content = JSON.stringify(this.swiperList)
              } else {
                this.$message.error('请上传轮播图')
                return
              }
            }
          } else {
            if (this.ruleForm.floorStyle == 22) {
              if (this.ruleForm.advertisement) {
                let obj = {img: this.ruleForm.advertisement, jump: this.ruleForm.guanggaoLink}
                data.content = JSON.stringify(obj)
              } else {
                this.$message.error('请上传广告图')
                return
              }
            } else if (this.ruleForm.floorStyle == 23) {
              if (this.ruleForm.goodPhoto.length) {
                data.content = JSON.stringify(this.ruleForm.goodPhoto)
              } else {
                this.$message.error('请上传图片')
                return
              }
            } else if (this.ruleForm.floorStyle == 18) {
              if (this.zhuanTi1.length) {
                data.content = JSON.stringify(this.zhuanTi1)
              } else {
                this.$message.error('请上传专题图')
                return
              }
            } else if (this.ruleForm.floorStyle == 19) {
              if (this.zhuanTi2.length) {
                data.content = JSON.stringify(this.zhuanTi2)
              } else {
                this.$message.error('请上传专题图')
                return
              }
            } else if (this.ruleForm.floorStyle == 20) {
              if (this.zhuanTi3.length) {
                data.content = JSON.stringify(this.zhuanTi3)
              } else {
                this.$message.error('请上传专题图')
                return
              }
            } else if (this.ruleForm.floorStyle == 21) {
              if (!this.tijiaoShopList.length) {
                this.$message.error('请选择商品')
                return
              } else {
                data.dataIds = this.tijiaoShopList.map(item => item.goodsId).join(',')
                data.dataType = 'GOODS'
              }
            } else {
              if (this.ruleForm.floorType == 0) {
                if (!this.title) {
                  this.$message.error('请选择类型')
                  return
                } else {
                  if (this.tijiaoArcicleList.length) {
                    data.title = this.title
                    data.dataIds = this.tijiaoArcicleList.map(item => item.workId).join(',')
                    data.dataType = 'WORK'
                  } else {
                    this.$message.error('请选择文章')
                    return
                  }
                }
              } else {
                if (this.dataInfo.assWebsiteStoreyOutTypeModelList.length || this.dataInfo.workModelList.length) {
                  data.assWebsiteStoreyOutTypeModel = {
                    typeId: localStorage.getItem('typeId'),
                    typeName: this.customize_part
                  }
                  data.dataType = 'WORK'
                } else {
                  this.$message.error('请选择文章或上传文章')
                  return
                }
              }
            }
          }
          // console.log(data)
          // return
          try {
            if (this.$route.query.storeyId) {
              data.storeyId = this.$route.query.storeyId
              updateAssWebsiteStorey(data).then(res => {
                if (res.code == '200') {
                  this.$message.success('保存成功')
                  localStorage.removeItem('typeId')
                  this.$router.push({
                    path: `/floorManagement`,
                    query: {
                      pageId: this.$route.query.pageId
                    },
                  });
                }
              })
            } else {
              addAssWebsiteStorey(data).then(res => {
                if (res.code == '200') {
                  this.$message.success('保存成功')
                  localStorage.removeItem('typeId')
                  this.$router.push({
                    path: `/floorManagement`,
                    query: {
                      pageId: this.$route.query.pageId
                    },
                  });
                }
              })
            }
          } catch (error) {
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    baocun() {
      if (!this.customize_part) {
        this.$message.error('请输入栏目名称！')
        return
      }
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        fatherId: 0,
        level: 1,
        typeName: this.customize_part
      }
      if (localStorage.getItem("typeId")) {
        ajax.typeId = localStorage.getItem("typeId")
        updateAssWebsiteStoreyOutType(ajax)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success('修改成功！')
              }
            })
            .catch(err => {
              this.$message({
                type: 'error',
                message: err.message
              });
            });
      } else {
        addAssWebsiteStoreyOutType(ajax)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success('新增成功！')
                localStorage.setItem('typeId', res.data.typeId)
                let typeId = res.data.typeId
                this.getAddType(typeId)
              }
            })
            .catch(err => {
              this.$message({
                type: 'error',
                message: err.message
              });
            });
      }
    },
    getAddType(typeId) {
      let ajax = {
        typeId: typeId ? typeId : localStorage.getItem('typeId'),
      }
      queryAssWebsiteStoreyOutTypeById(ajax)
          .then((res) => {
            if (res.code == 200) {
              this.customize_part = res.data.typeName
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    addType() {
      if (this.customize_part && localStorage.getItem('typeId')) {
        this.getSmallType()
        this.centerDialogVisible_type = true
      } else {
        this.$message.error('请先新增栏目！')
        return
      }
    },
    determine() {
      this.$refs.ruleForm_fl.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              typeName: this.ruleForm_fl.title,
              level: 2,
              fatherId: localStorage.getItem("typeId")
            };
            if (this.update_type_edit) {
              data.typeId = this.ruleForm_fl.typeId
              await updateAssWebsiteStoreyOutType(data);
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.getAddWzList()
            } else {
              await addAssWebsiteStoreyOutType(data);
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.getSmallType()
            }
          } catch (error) {
            this.$message({
              message: error.message,
              type: "error",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    part_erji() {
      this.centerDialogVisible_type = false
    },
    getSmallType() {
      let ajax = {
        fatherId: localStorage.getItem('typeId'),
      }
      queryAssWebsiteStoreyOutType(ajax)
          .then((res) => {
            if (res.code == 200) {
              this.smallList = res.data
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    close_part() {
      (this.ruleForm_fl = {
        title: "",
      }),
          this.centerDialogVisible_type = false;
      this.update_type_edit = false
    },
    delSmall(typeId) {
      let ajax = {
        typeId: typeId,
      }
      delAssWebsiteStoreyOutType(ajax)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功！')
              this.getSmallType()
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    chooseNR_part(num, type) {
      if (localStorage.getItem('typeId')) {
        if (type == 1) {
          this.zidingyi_xz = 1
        } else {
          this.zidingyi_xz = 2
        }
        if (num <= 7) {
          this.workType = 1
        } else {
          this.workType = 0
        }
      } else {
        this.$message.error('请先新增保存栏目！')
        return
      }
    },
    zdy_choose() {
      this.queryPage_add()
    },
    async queryPage_add(payload) {
      let data = {
        associationId: localStorage.getItem("associationId"),
        pageSize: this.pageSize,
        pageNum: this.currentPage_add,
        ...payload
      };
      if (this.ruleForm.floorStyle <= 7 && this.ruleForm.floorStyle > 0) {
        data.workType = 1
      } else {
        data.workType = 0
      }
      try {
        this.loading_add = true;
        const result = await selectWorkPageList(data);
        if (result.data) {
          this.loading_add = false;
          const {total, list} = result.data.pageInfo;
          for (let i in list) {
            list[i].createTime = this.renderTime(list[i].createTime)
          }
          this.tableData_add = list;
          this.total_add = total;
          this.centerDialogVisible_zdy_choose = true
        } else {
          this.tableData_add = []
          this.$message.error(result.message)
          this.loading_add = false;
        }
      } catch (error) {
        this.tableData_add = []
        this.loading_add = false;
      }
    },
    zdy_upload(type) {
      this.uploadType = type
      this.getSmallType()
      this.centerDialogVisible_add = true
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      this.ruleForm_add.videoUrl = `https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/${res.data}`;
    },
    beforeAvatarUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 20MB!");
      }
      return isLt2M;
    },
    handleRemove_add(file, fileList) {
      this.fileList_add = []
      this.ruleForm_add.coverImg = "";
    },
    handleSuccess_add(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm_add.coverImg = this.ossUrl + file.response.data
      this.fileList_add = fileList;
    },
    beforeAvatarUploadImg(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return isLt2M;
    },
    add_WZ() {
      if (this.ruleForm.floorStyle <= 7 && this.ruleForm.floorStyle > 0) {
        if (this.ruleForm_add.videoUrl) {
        } else {
          this.$message.error('请上传视频')
          return
        }
      }
      if (this.ruleForm.floorStyle == 1 || this.ruleForm.floorStyle == 8) {
        if (this.zidingyi_xz == 2) {
          if (!this.ruleForm_add.type) {
            this.$message.error('请选择类型')
            return
          }
        }
      }
      this.$refs.ruleForm_add.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              name: this.ruleForm_add.title,
              orderBy: this.ruleForm_add.sort,
              typeId: this.ruleForm_add.type ? this.ruleForm_add.type : localStorage.getItem('typeId'),
              remark: this.ruleForm_add.details,
              coverUrl: this.ruleForm_add.coverImg,
            };
            if (this.ruleForm.floorStyle <= 7 && this.ruleForm.floorStyle > 0) {
              data.url = this.ruleForm_add.videoUrl
              data.workType = 1
            } else {
              data.workType = 0
            }
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              data.dataId = this.workId
              await updateAssWebsiteStoreyOutData(data);
            } else {
              await addAssWebsiteStoreyOutData(data);
            }
            this.centerDialogVisible_add = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.zidingyi_xz = 0
            await this.getAddWzList();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible_add = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getAddWzList() {
      let ajax = {
        typeId: localStorage.getItem('typeId'),
      }
      queryOutTypeData(ajax)
          .then((res) => {
            if (res.code == 200) {
              if (res.data.workModelList) {
                this.dataInfo.workModelList = res.data.workModelList
              }
              if (res.data.assWebsiteStoreyOutTypeModelList) {
                this.dataInfo.assWebsiteStoreyOutTypeModelList = res.data.assWebsiteStoreyOutTypeModelList
              }
              this.$forceUpdate()
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    close_add() {
      (this.ruleForm_add = {
        title: "",
        sort: "",
        type: "",
        details: "",
        coverImg: "",
        videoUrl: "",
      }),
          this.fileList_add = []
      this.centerDialogVisible_add = false;
      this.editUpdate = false
      this.zidingyi_xz = 0
    },
    update_add_wz(item, num) {
      console.log(item)
      this.centerDialogVisible_add = true
      this.editUpdate = true
      this.ruleForm_add.title = item.name
      this.ruleForm_add.sort = item.orderBy
      if (num == 2) {
        this.ruleForm_add.type = item.typeId
      }
      this.ruleForm_add.details = item.remark
      this.fileList_add = [{url: item.coverUrl, uid: 1}];
      this.ruleForm_add.coverImg = item.coverUrl
      this.workId = item.workId
      if (this.ruleForm.floorStyle <= 7 && this.ruleForm.floorStyle > 0) {
        this.ruleForm_add.videoUrl = item.url
      }
    },
    del_add_wz(item) {
      let ajax = {
        dataId: item.workId,
        typeId: item.typeId,
        dataFrom: item.dataFrom
      }
      delAssWebsiteStoreyOutData(ajax)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功！')
              this.getAddWzList()
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    update_add_type(item) {
      this.typeTitle = '修改分类'
      this.update_type_edit = true
      this.centerDialogVisible_type = true
      this.ruleForm_fl.title = item.typeName
      this.ruleForm_fl.typeId = item.typeId
    },
    del_add_type(item) {
      let ajax = {
        typeId: item.typeId,
      }
      delAssWebsiteStoreyOutType(ajax)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功！')
              this.getAddWzList()
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    handleSizeChange_add(val) {
      this.pageSize = val;
      this.queryPage_add();
    },
    handleCurrentChange_add(val) {
      this.currentPage_add = val;
      this.queryPage_add();
    },
    handleSelectionChange_add(val) {
      this.multipleSelection_add = val;
    },
    filterNames() {
      this.currentPage_add = 1;
      let data = {
        name: this.filterName,
      };
      this.queryPage_add(data);
    },
    toAddOld() {
      if (this.multipleSelection_add.length == 0) {
        this.$message.error('请选择数据！')
        return;
      }
      if (this.ruleForm.floorStyle == 1 || this.ruleForm.floorStyle == 8) {
        if (this.zidingyi_xz == 2) {
          if (!this.filterType) {
            this.$message.error('请选择类型！')
            return;
          }
        }
      }
      let oldIds = this.multipleSelection_add.map(item => item.workId).join(',')
      let ajax = {
        typeId: this.filterType ? this.filterType : localStorage.getItem('typeId'),
        oldIds: oldIds,
      }
      if (this.ruleForm.floorStyle <= 7 && this.ruleForm.floorStyle > 0) {
        ajax.dataType = 'WORK_VIDEO'
      } else {
        ajax.dataType = 'WORK'
      }
      addBindOldDataByType(ajax)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功！')
              this.getAddWzList()
              this.centerDialogVisible_zdy_choose = false
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    choose_type() {
      this.ifJY = true
    },
    reselect() {
      this.$confirm('此操作将删除上传数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(this.ruleForm.floorType)
        if (this.ruleForm.floorType == 1 && localStorage.getItem('typeId')) {
          let ajax = {
            typeId: localStorage.getItem('typeId'),
          }
          cleanDataTypeByBigType(ajax)
              .then((res) => {
                if (res.code == 200) {
                  this.ruleForm.floorStyle = null
                  this.ifJY = false
                  localStorage.removeItem('typeId')
                  this.customize_part = ''
                  this.smallList = []
                  this.dataInfo = {
                    workModelList: [],
                    assWebsiteStoreyOutTypeModelList: []
                  }
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                }
              })
              .catch(err => {
                this.$message({
                  type: 'error',
                  message: err.message
                });
              });
        } else if (this.ruleForm.floorType == 0) {
          this.tijiaoArcicleList = []
          this.title = '+ 选择类型'
          this.classType = ''
          this.ifJY = false
          this.zhuanTi1 = [{}]
          this.zhuanTi2 = [{}, {}]
          this.zhuanTi3 = [{}, {}, {}]
          this.swiperList = []
          this.ruleForm.floorStyle =  null
        } else {
          console.log('000')
          this.ruleForm.floorStyle = null
          this.ifJY = false
          this.customize_part = ''
          this.smallList = []
          this.dataInfo = {
            workModelList: [],
            assWebsiteStoreyOutTypeModelList: []
          }
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
  destroyed() {
    localStorage.removeItem('typeId')
  }
}
</script>

<style scoped lang="scss">
.ruleForm1{
  width: 100%;
}
.ruleForm{
  width: 50%;
}
.con_wrap {
  display: flex;
  flex-direction: column;
  //height: 47vh;
  overflow: auto;

  .decorate-center {
    display: flex;

    .style_img {
      width: 640px;
      background: #F4F5F6;
      border-radius: 4px;
      padding: 20px 20px 0 20px;
      position: relative;

      .img {
        width: 100%;
        height: 240px;
      }

      .title_wtap {
        display: flex;
        justify-content: space-between;

        .choose_title_zdy {
          /deep/ .el-input__inner {
            background-color: transparent;
            border: 1px dashed #51CDCB;
          }

          .input_title {
            width: 360px;
            height: 36px;
            border-radius: 4px;
          }

          .button_title {
            margin-left: 10px;
          }

          /deep/ .el-input__inner::placeholder {
            color: #51CDCB;
            font-size: 16px;
          }

          /deep/ .el-input__inner::-webkit-input-placeholder {
            color: #51CDCB;
            font-size: 16px;
          }

          /deep/ .el-input__inner:-moz-placeholder {
            color: #51CDCB;
            font-size: 16px;
          }

          /deep/ .el-input__inner:-ms-input-placeholder {
            color: #51CDCB;
            font-size: 16px;
          }
        }

        .choose_title {
          cursor: pointer;
          padding: 0 10px;
          height: 34px;
          background: #51CDCB;
          border: 1px solid #51CDCB;
          border-radius: 4px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
        }

        .button_wrap {
          display: flex;

          .button1 {
            cursor: pointer;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #51CDCB;
          }

          .button {
            display: flex;
            margin-left: 16px;
            cursor: pointer;

            img {
              align-self: center;
              width: 16px;
              height: 16px;
              margin-right: 5px;
            }

            sapn {
              align-self: center;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #51CBCD;
            }
          }
        }
      }
    }

    .choose_nr {
      margin-top: 15px;
      cursor: pointer;
      width: 120px;
      height: 34px;
      background: #51CDCB;
      border: 1px solid #51CDCB;
      border-radius: 4px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
    }

    .part_sc_wrap {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      width: 460px;
      height: 100px;
      justify-content: flex-end;
      align-items: center;

      .part_sc {
        display: flex;
      }

    }

    .part_sc_wrap1 {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      width: 460px;
      height: 100px;
      justify-content: space-between;
      align-items: center;

      .part_sc {
        display: flex;
      }
    }

    .choose_nr_part {
      cursor: pointer;
      width: 120px;
      text-align: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FEFEFE;
      height: 30px;
      line-height: 30px;
      background: #51CDCB;
      border-radius: 4px;
      align-self: center;
    }

    .part_xz {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      width: 96px;
      padding: 0 10px;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 0px 40px 0px rgba(49, 49, 49, 0.1);
      cursor: pointer;

      div:nth-child(1) {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #51CCCA;
        border-bottom: 1px solid #EEEEEE;
      }

      div:nth-child(2) {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #828488;
      }
    }

    .left_wrap {
      height: 400px;
      overflow: auto;
      flex: 1;
      padding: 0 30px;
      background: #f5f5f5;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, .05);
      position: relative;
    }

    ::-webkit-scrollbar {
      width: 4px;
      height: 1px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
      background: #8c8c8c;
    }

    //右侧
    .attribute-tabs {
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      width: 400px;
      position: relative;

      .attribute-header {
        padding: 14px 20px;
        border-bottom: 1px solid hsla(0, 0%, 89.8%, .898039);

        .attribute-title {
          flex: 1;
          font-size: 16px;
          font-weight: 500;
          box-sizing: border-box;
        }
      }

      .attribute-content {
        .attribute-item {
          .title {
            line-height: 1.3;
            padding: 20px 20px 0;
            font-size: 14px;
          }

          .content1 {
            padding: 20px 20px;

            .nav-item {
              background: #f9f9f9;
              padding: 15px 20px 0;
              cursor: move;
              margin-bottom: 20px;

              .input_wrap {
                display: flex;
                margin-bottom: 20px;

                .el-form-item__label {
                  width: 45px;
                  vertical-align: middle;
                  float: left;
                  font-size: 12px;
                  color: #666;
                  padding: 0 12px 0 0;
                  box-sizing: border-box;
                }

                .el-input--small {
                  flex: 1;
                  cursor: pointer;

                  ///deep/ input {
                  //  cursor: pointer !important;
                  //}
                }
              }


              .input_wrap1 {
                margin-top: 15px;
                display: flex;
                margin-bottom: 20px;

                .el-form-item__label {
                  width: 60px;
                  vertical-align: middle;
                  float: left;
                  font-size: 12px;
                  color: #666;
                  padding: 0 12px 0 0;
                  box-sizing: border-box;
                }

                .el-input--small {
                  flex: 1;
                  cursor: pointer;

                  ///deep/ input {
                  //  cursor: pointer !important;
                  //}
                }
              }

              .smallImg {
                width: 100%;
                height: 136px;

                /deep/ .el-upload--picture-card {
                  width: 100%;
                  height: 136px;
                }

                /deep/ .el-upload-list--picture-card {
                  width: 0;
                  height: 0;
                  display: none;
                }

                img {
                  width: 100%;
                  height: 136px;
                  border-radius: 5px;
                  object-fit: cover;
                }
              }
            }

            .ls-icon-del {
              //display: none;
              position: absolute;
              top: -8px;
              right: -8px;
              line-height: 16px;
              width: 16px;
              height: 16px;
              background-color: rgba(0, 0, 0, .3);
              border-radius: 50%;
              text-align: center;
              color: #fff;
              font-weight: 700;
              cursor: pointer;
            }
          }
        }
      }
    }

    //banner
    .banner {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    //广告图
    .gg_wrap {
      /deep/ .el-upload--picture-card {
        width: 800px;
      }

      /deep/ .el-upload-list--picture-card {
        width: 0;
        height: 0;
        display: none;
      }

      .avatar {
        width: 100%;
        height: 100%;
      }
    }

    //专题
    .zt1_wrap {
      /deep/ .el-upload--picture-card {
        width: 600px;
        height: 200px;
        line-height: 200px;
      }

      /deep/ .el-upload-list--picture-card {
        width: 0;
        height: 0;
        display: none;
      }

      .avatar {
        width: 100%;
        height: 100%;
      }
    }

    .zt2_wrap {
      /deep/ .el-upload--picture-card {
        width: 300px;
      }

      /deep/ .el-upload-list--picture-card {
        width: 0;
        height: 0;
        display: none;
      }

      .avatar {
        width: 100%;
        height: 100%;
      }
    }

    .zt3_wrap {
      /deep/ .el-upload--picture-card {
        width: 240px;
        height: 130px;
        line-height: 130px;
      }

      /deep/ .el-upload-list--picture-card {
        width: 0;
        height: 0;
        display: none;
      }

      .avatar {
        width: 100%;
        height: 100%;
      }
    }
  }

  .marginTop {
    margin-top: 15px;
  }

  .list_wrap {
    margin-left: 30px;
    width: 400px;
    height: 100%;
    overflow: auto;

    .item_wrap {
      display: flex;

      div:nth-child(1) {
        background: #51CDCB;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: 5px;
        align-self: center;
      }

      div:nth-child(2) {
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      img {
        margin-left: 10px;
        align-self: center;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
}

//弹窗
.tanchaung {
  .nav-list {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    background: #FFFFFF;

    .nav-item {
      display: inline-block;
      vertical-align: middle;
      border: 1px solid hsla(0, 0%, 89.8%, .898039);
      padding: 6px 15px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      border-radius: 4px;
    }

    .default {
      color: #606B83;
      border-top: 5px solid #fff;
    }

    .active {
      font-weight: bold;
      border: 1px solid #51CDCB;
      color: #51CDCB;
    }
  }

  .jump_wrap {
    padding: 15px;
    display: flex;

    .title {
      align-self: center;
      margin-right: 15px;
    }

    .title:before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}

.tanchaung1 {
  .nav-list {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    background: #FFFFFF;

    .nav-item {
      display: inline-block;
      vertical-align: middle;
      border: 1px solid hsla(0, 0%, 89.8%, .898039);
      padding: 6px 15px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      border-radius: 4px;
    }

    .default {
      color: #606B83;
      border-top: 5px solid #fff;
    }

    .active {
      font-weight: bold;
      border: 1px solid #51CDCB;
      color: #51CDCB;
    }
  }

  .jump_wrap {
    padding: 15px;
    display: flex;

    .title {
      align-self: center;
      margin-right: 15px;
    }

    .title:before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}

.reclassify_list {
  display: flex;
  flex-wrap: wrap;

  .reclassify_item {
    height: 34px;
    line-height: 34px;
    background: #FFFFFF;
    border: 1px solid #51CDCA;
    border-radius: 4px;
    position: relative;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #51CDCA;
    padding: 0 16px;
    margin-bottom: 16px;
    margin-right: 16px;

    .close_z {
      width: 18px;
      height: 18px;
      background: rgba(34, 34, 34, 0.7);
      border-radius: 50%;
      color: #fff;
      position: absolute;
      top: -9px;
      right: -9px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.right_list_wrap {
  width: 40%;
  margin-left: 36px;
  background: #FFFFFF;
  border: 2px dashed #E7EBEE;
  border-radius: 8px 10px 10px 10px;
  padding: 10px 24px;

  .list_wrap {
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    border-bottom: 1px solid #F4F5F6;
    padding-bottom: 6px;
    padding-top: 20px;

    .item_wrap {
      display: flex;
      justify-content: space-between;
      width: 46%;
      margin-bottom: 10px;

      &:nth-child(2n+1) {
        margin-right: 30px;
      }

      div:nth-child(1) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #3E3E3E;
      }

      .icon_wrap {
        display: flex;

        img {
          margin-left: 10px;
          align-self: center;
          width: 16px;
          height: 16px;
          margin-right: 5px;
          cursor: pointer;
          white-space: nowrap;
        }
      }
    }
  }

  .list_wrap1 {
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    border-bottom: 1px solid #F4F5F6;
    padding-bottom: 6px;
    padding-top: 20px;

    .item_wrap {
      display: flex;
      justify-content: space-between;
      width: 46%;
      margin-bottom: 10px;

      &:nth-child(2n+1) {
        margin-right: 30px;
      }

      div:nth-child(1) {
        background: #51CDCB;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: 5px;
        align-self: center;
      }

      div:nth-child(2) {
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      img {
        margin-left: 10px;
        align-self: center;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }

  .fenlei_list_wrap {
    padding-top: 20px;
  }

  .fenlei_wrap {
    display: flex;
    justify-content: space-between;

    .fenlei_title {
      height: 30px;
      line-height: 30px;
      border: 1px solid #51CDCB;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #51CDCB;
      padding: 0 12px;
    }

    .fenlei_button {
      display: flex;

      .button {
        display: flex;
        cursor: pointer;

        &:nth-child(1) {
          margin-right: 20px;
        }

        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
          align-self: center;
        }

        span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #51CBCD;
          align-self: center;
        }
      }
    }
  }
}

.avatar-uploader {
  /deep/ .el-upload--text {
    line-height: 178px;

    .avatar-uploader-icon {
      font-size: 40px;
    }
  }
}

.leixing_wrap {
  display: flex;
  box-shadow: 0px 6px 10px 0px rgba(49, 49, 49, 0.04);
  padding-bottom: 15px;

  .leixing_title {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #606266;
    align-self: center;
    margin-right: 15px;
  }

  //.leixing_title::before{
  //  content: '*';
  //  color: #F56C6C;
  //  margin-right: 4px;
  //}
}
</style>
